<template>
  <!-- 账户管理 -->
  <div class="accountManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :reset="true"
      :form-inline="formInline"
      :add-row="editAndAddRow"
      :search-form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog ref="dialog" :add-and-edit-form-item="addAndEditFormItem" :edit-form-data="editFormData" @getFormData="getFormData" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import Dialog from '@/components/Dialog'
import { dataSources } from '@/config/index'
import { cmpAccountPage, addAndEditCmpAccount, cmpAccountEnableOrDisablt } from '@/http/agentApi/enterpriseInformation'
export default {
  components: { FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      itemData: [
        { label: '账户名称', prop: 'accName' },
        { label: '银行账号', prop: 'accNo' },
        { label: '开户行网点', prop: 'accOpenNet' },
        { label: '状态', prop: 'state', child: [{ id: '01', name: '启用' }, { id: '02', name: '禁用' }] },
        { label: '数据来源', prop: 'dataSource', child: dataSources }
      ],
      addAndEditFormItem: [
        { label: '账户名称', type: 'input', value: 'accName' },
        { label: '银行账号', type: 'input', value: 'accNo', inputType: 'number', rules: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ] },
        { label: '开户行网点', type: 'input', value: 'accOpenNet' },
        {
          label: '状态',
          type: 'select',
          value: 'state',
          optionLabel: 'name',
          optionId: 'id',
          child: [{ id: '01', name: '启用' }, { id: '02', name: '禁用' }]
        }
      ],
      editFormData: {},
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        { num: '02', val: 'state', bType: 'primary', label: '启用', handleEvent: this.enableDisableRow },
        { num: '01', val: 'state', bType: 'danger', label: '禁用', handleEvent: this.enableDisableRow }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        { type: 'select', label: '状态', value: 'state', pLabel: 'name', pValue: 'id', child: [{ id: '01', name: '启用' }, { id: '02', name: '禁用' }] }
      ]
    }
  },
  mounted() {
    this.formInline.cmpCode = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)).cmpId
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      cmpAccountPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.editFormData = { ...row } || {}
      this.$refs.dialog.editFormVisible = true
    },
    // 启用与禁用
    enableDisableRow(row) {
      this.$confirm('此操作将改变此条信息状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cmpAccountEnableOrDisablt({ id: row.id, state: row.state === '02' ? '01' : '02' }, res => {
          this.$message.success('成功！')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取到输入框的内容
    getFormData(value) {
      // 录入方是企业
      value.dataSource = '01'
      value.cmpCode = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)).cmpId
      addAndEditCmpAccount([value], () => {
        this.$message.success('成功')
        this.$refs.dialog.editFormVisible = false
        this.getdata()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.marginTop {
  padding-top: 18px;
}
</style>
